@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comfortaa');
@import url('https://fonts.googleapis.com/css2?family=Rubik');
@import url('https://fonts.googleapis.com/css2?family=Roboto');

body {
  margin: 0;
  font-family: 'Comfortaa', 'Rubik', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Comfortaa', 'Rubik', 'Roboto', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
